import React from 'react';
import PostPreview from './PostPreview';

const PostFeed = ({ posts }) => {
  if (!posts.length) {
    const message = "There's nothing here yet...";
    return <p>{message}</p>;
  }

  return posts.map((post) => <PostPreview key={post.slug} post={post} />);
};

export default PostFeed;
