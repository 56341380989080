import React from 'react';

import Categories from './Categories';
import PostFeed from './PostFeed';
import Footer from '../Footer';
import BuildRelationshipsSection from '../sections/BuildRelationshipsSection';
import RecentPosts from './RecentPosts';
import Seo from '../Seo';
import Header from '../Header';

const Blog = ({ data }) => {
  const { allWpPost, allWpCategory } = data;
  const postNodes = allWpPost.edges.map((edge) => edge.node);
  const categories = allWpCategory.nodes.map((node) => node.name).filter((name) => name !== 'Uncategorized');

  return (
    <>
      <Seo
        title="Subscribe Pro Blog"
        description="The Subscribe Pro blog features articles covering topics in e-commerce, subscription commerce, customer retention and churn reduction."
      />

      <Header graphic={undefined}>Subscribe Pro Blog</Header>

      <div className="max-w-screen-2xl mx-auto px-4 pt-12 sm:px-6 sm:pt-16 lg:flex lg:justify-between lg:px-8 lg:pt-20 mb-16">
        <div className="order-last lg:ml-12 mb-16">
          <RecentPosts menu={postNodes} />
          <Categories categories={categories} />
        </div>

        <main className="order-1 max-w-[60rem]">
          <div className="">
            <PostFeed posts={postNodes} />
          </div>
        </main>
      </div>

      <BuildRelationshipsSection />

      <Footer />
    </>
  );
};

export default Blog;
