import React from 'react';
import { Link } from 'gatsby';

const Filter = (props) => {
  const { topic } = props;
  return (
    <li>
      <Link to={`/blog/category/${topic.toLowerCase()}`} className="hover:text-sp-green">
        {topic}
      </Link>
    </li>
  );
};

const Categories = ({ className, categories }) => (
  <ul
    className={`text-sm leading-6 text-slate-700 lg:sticky lg:top-16 lg:-mt-16 lg:h-screen lg:w-72 lg:overflow-y-auto lg:py-16 ${className}`}
  >
    <li>
      <span className="font-semibold text-slate-900">Categories</span>
      <ul className="mt-4 space-y-2 border-l border-slate-200 pl-6">
        <li>
          <Link className="hover:text-sp-green" to="/blog">
            All
          </Link>
        </li>
        {categories.map((topic) => (
          <Filter key={topic} topic={topic} />
        ))}
      </ul>
    </li>
  </ul>
);

export default Categories;
