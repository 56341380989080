import React from 'react';
import moment from 'moment';

import '../../css/hide-rel-bookmark-links.css';
import Prose from '../Prose';

const PostPreview = ({ post }) => {
  const { title, date, excerpt, slug } = post;

  const uri = `/blog/${slug}`;

  const processedExcerpt = excerpt.replace(/\s\[&hellip;]/, '...');

  return (
    <article className="my-12">
      <header>
        <time dateTime={moment(date).format('MMMM D, YYYY')} className="faded text-sm">
          {moment(date).format('MMMM D, YYYY')}
        </time>
        <br />
        <h2 className="!mb-3 !mt-0">
          <a href={uri} className="text-xl">
            {title}
          </a>
        </h2>
      </header>

      <Prose content={processedExcerpt} />
    </article>
  );
};

export default PostPreview;
