import React from 'react';
import { Link } from 'gatsby';
import moment from 'moment';

const RecentPosts = ({ menu }) => {
  const posts = menu.sort((a, b) => new Date(b.date) - new Date(a.date));
  return (
    <ul className="text-sm leading-6 text-slate-700 lg:w-72 lg:overflow-y-auto mb-6">
      <li>
        <span className="font-semibold text-slate-900">Recent Posts</span>
        <ul className="mt-4 space-y-2 border-l border-slate-200 pl-6">
          {posts.slice(0, 10).map((item) => {
            if (item.external) {
              return (
                <li>
                  <a key={item.uri} href={item.uri} className="">
                    {item.title}
                  </a>
                </li>
              );
            }
            const formattedURI = `/blog/${item.slug}`;
            return (
              <li>
                <Link key={item.slug} to={formattedURI} className=" ">
                  {item.title}
                </Link>
              </li>
            );
          })}
        </ul>
      </li>
    </ul>
  );
};

export default RecentPosts;
